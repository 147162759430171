import(/* webpackMode: "eager" */ "/codebuild/output/src2042242522/src/xana-website/node_modules/@next/third-parties/dist/google/ga.js");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src2042242522/src/xana-website/node_modules/@next/third-parties/dist/google/gtm.js");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src2042242522/src/xana-website/node_modules/@next/third-parties/dist/ThirdPartyScriptEmbed.js");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src2042242522/src/xana-website/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src2042242522/src/xana-website/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src2042242522/src/xana-website/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src2042242522/src/xana-website/node_modules/next/font/local/target.css?{\"path\":\"src/app/fonts.jsx\",\"import\":\"\",\"arguments\":[{\"src\":[{\"path\":\"../../public/fonts/FreizeitTrial/FreizeitTrial-Thin.woff\",\"weight\":\"100\",\"style\":\"normal\"},{\"path\":\"../../public/fonts/FreizeitTrial/FreizeitTrial-ThinSlanted.woff\",\"weight\":\"100\",\"style\":\"italic\"},{\"path\":\"../../public/fonts/FreizeitTrial/FreizeitTrial-ExtraLight.woff\",\"weight\":\"200\",\"style\":\"normal\"},{\"path\":\"../../public/fonts/FreizeitTrial/FreizeitTrial-ExtraLightSlanted.woff\",\"weight\":\"200\",\"style\":\"italic\"},{\"path\":\"../../public/fonts/FreizeitTrial/FreizeitTrial-Light.woff\",\"weight\":\"300\",\"style\":\"normal\"},{\"path\":\"../../public/fonts/FreizeitTrial/FreizeitTrial-LightSlanted.woff\",\"weight\":\"300\",\"style\":\"italic\"},{\"path\":\"../../public/fonts/FreizeitTrial/FreizeitTrial-Regular.woff\",\"weight\":\"400\",\"style\":\"normal\"},{\"path\":\"../../public/fonts/FreizeitTrial/FreizeitTrial-RegularSlanted.woff\",\"weight\":\"400\",\"style\":\"italic\"},{\"path\":\"../../public/fonts/FreizeitTrial/FreizeitTrial-Medium.woff\",\"weight\":\"500\",\"style\":\"normal\"},{\"path\":\"../../public/fonts/FreizeitTrial/FreizeitTrial-MediumSlanted.woff\",\"weight\":\"500\",\"style\":\"italic\"},{\"path\":\"../../public/fonts/FreizeitTrial/FreizeitTrial-Bold.woff\",\"weight\":\"600\",\"style\":\"normal\"},{\"path\":\"../../public/fonts/FreizeitTrial/FreizeitTrial-BoldSlanted.woff\",\"weight\":\"600\",\"style\":\"italic\"},{\"path\":\"../../public/fonts/FreizeitTrial/FreizeitTrial-ExtraBold.woff\",\"weight\":\"700\",\"style\":\"normal\"},{\"path\":\"../../public/fonts/FreizeitTrial/FreizeitTrial-ExtraBoldSlanted.woff\",\"weight\":\"700\",\"style\":\"italic\"}],\"variable\":\"--font-FreizeitTrial\"}],\"variableName\":\"FreizeitTrial\"}");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src2042242522/src/xana-website/node_modules/next/font/local/target.css?{\"path\":\"src/app/fonts.jsx\",\"import\":\"\",\"arguments\":[{\"src\":[{\"path\":\"../../public/fonts/AvanttTrial/Avantt-Thin.woff\",\"weight\":\"100\",\"style\":\"normal\"},{\"path\":\"../../public/fonts/AvanttTrial/Avantt-ThinItalic.woff\",\"weight\":\"100\",\"style\":\"italic\"},{\"path\":\"../../public/fonts/AvanttTrial/Avantt-Light.woff\",\"weight\":\"200\",\"style\":\"normal\"},{\"path\":\"../../public/fonts/AvanttTrial/Avantt-LightItalic.woff\",\"weight\":\"200\",\"style\":\"italic\"},{\"path\":\"../../public/fonts/AvanttTrial/Avantt-Regular.woff\",\"weight\":\"300\",\"style\":\"normal\"},{\"path\":\"../../public/fonts/AvanttTrial/Avantt-RegularItalic.woff\",\"weight\":\"300\",\"style\":\"italic\"},{\"path\":\"../../public/fonts/AvanttTrial/Avantt-Medium.woff\",\"weight\":\"400\",\"style\":\"normal\"},{\"path\":\"../../public/fonts/AvanttTrial/Avantt-MediumItalic.woff\",\"weight\":\"400\",\"style\":\"italic\"},{\"path\":\"../../public/fonts/AvanttTrial/Avantt-SemiBold.woff\",\"weight\":\"500\",\"style\":\"normal\"},{\"path\":\"../../public/fonts/AvanttTrial/Avantt-SemiBoldItalic.woff\",\"weight\":\"500\",\"style\":\"italic\"},{\"path\":\"../../public/fonts/AvanttTrial/Avantt-Bold.woff\",\"weight\":\"600\",\"style\":\"normal\"},{\"path\":\"../../public/fonts/AvanttTrial/Avantt-BoldItalic.woff\",\"weight\":\"600\",\"style\":\"italic\"},{\"path\":\"../../public/fonts/AvanttTrial/Avantt-ExtraBold.woff\",\"weight\":\"700\",\"style\":\"normal\"},{\"path\":\"../../public/fonts/AvanttTrial/Avantt-ExtraBoldItalic.woff\",\"weight\":\"700\",\"style\":\"italic\"}],\"variable\":\"--font-AvanttTrial\"}],\"variableName\":\"AvanttTrial\"}");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src2042242522/src/xana-website/src/app/globals.scss");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/codebuild/output/src2042242522/src/xana-website/src/components/ui/Breadcrumbs/Breadcrumbs.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/codebuild/output/src2042242522/src/xana-website/src/components/ui/Footer/Footer.jsx");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src2042242522/src/xana-website/src/components/ui/Footer/style.module.scss");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/codebuild/output/src2042242522/src/xana-website/src/components/ui/LayoutPage/LayoutPage.jsx");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src2042242522/src/xana-website/src/components/ui/Loading/style.module.scss");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/codebuild/output/src2042242522/src/xana-website/src/components/ui/Navbar/Navbar.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/codebuild/output/src2042242522/src/xana-website/src/components/ui/Navbar/NavbarItem.jsx");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src2042242522/src/xana-website/src/components/ui/SystemColumn/style.module.scss");
