'use client'
import React, { useMemo, useState } from 'react'
import Link from 'next/link';
import { SVGDot } from '@/components/svg';
import { Button } from "@/components/shared";
import style from "./style.module.scss";

const transformText = (value, parts) => {
  let html = [];

  if (parts) {
    if (parts.length > 2) {
      const firstItems = parts.slice(0, 2).join('|');
      const lastItem = parts.slice(2);
      html = [
        `<div>`,
        ...firstItems.split('|').map((part, idx) => {
          const [day, hours] = part.split(':');
          return `<span><strong>${day}:&nbsp;</strong>${hours}</span>`;
        }).join('&nbsp; | &nbsp;'),
        '</div>',
        `<div>`,
        ...lastItem.map((part, idx) => {
          const [day, hours] = part.split(':');
          return `<span><strong>${day}:&nbsp;</strong>${hours}</span>`;
        }).join(''),
        '</div>'
      ];
      return html.join('');
    }

    if (parts.length < 3) {
      html = [
        `<div>`,
        value.split('|').map((part, idx) => {
          const [day, hours] = part.split(':');
          return `<span><strong>${day}:&nbsp;</strong>${hours}</span>`;
        }).join('&nbsp; | &nbsp;'),
        '</div>',
      ];
      return html.join('');
    }
  }
}



const PharmaciesItem = React.memo(({
  nombre,
  direccion,
  direccion_2,
  direccion_3,
  horario,
  numeroTelefono,
  gmap,
  tipo_telefono,
}) => {
  const parts = horario.split('|')

  return (
    <>
      <div className={`${style.section_address_map} `}>

        <div className={`${style.section_address_text} `}>
          <p className={`${style.address_text} `} dangerouslySetInnerHTML={{ __html: direccion }} />
          {direccion_3 && (
            <p className={`${style.address_ref_text} `} dangerouslySetInnerHTML={{ __html: direccion_3 }} />
          )}

          {direccion_2 && (
            <p className={`${style.address_text} `} dangerouslySetInnerHTML={{ __html: direccion_2 }} />
          )}

          <div className={`${style.schedule_container} `}>
            <div className={`${style.schedule_items_content} `}>
              <div
                className={`${style.schedule_time}
                ${parts.length > 2 ? 'flex-col' : 'flex-row'} `}
                dangerouslySetInnerHTML={{ __html: transformText(horario, parts) }} />
            </div>
          </div>

          <div className={`${style.phone_container} `}>
            <span className={`${style.span_label} `}>
              {tipo_telefono.tipo}:
            </span>
            <span
              className={`${style.span_phone} `}
              dangerouslySetInnerHTML={{ __html: numeroTelefono }}
            />
          </div>
        </div>

        <Link
          href={gmap} passHref
          target={"_blank"}
        >
          <Button type={'link_address'} title={'¿Cómo llegar?'} icon={'<svg fill=\"currentColor\" viewBox=\"0 0 16 16\" > <path fillRule=\"evenodd\" d=\"M4 8a.5.5 0 01.5-.5h5.793L8.146 5.354a.5.5 0 11.708-.708l3 3a.5.5 0 010 .708l-3 3a.5.5 0 01-.708-.708L10.293 8.5H4.5A.5.5 0 014 8z\" /> </svg>'} />
        </Link>
      </div>


    </>
  )
});

PharmaciesItem.displayName = 'PharmaciesItem';

export default PharmaciesItem