import(/* webpackMode: "eager" */ "/codebuild/output/src2042242522/src/xana-website/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src2042242522/src/xana-website/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/codebuild/output/src2042242522/src/xana-website/src/components/ui/Breadcrumbs/Breadcrumbs.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/codebuild/output/src2042242522/src/xana-website/src/components/ui/Footer/Footer.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/codebuild/output/src2042242522/src/xana-website/src/components/ui/LayoutPage/LayoutPage.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/codebuild/output/src2042242522/src/xana-website/src/components/ui/Navbar/Navbar.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/codebuild/output/src2042242522/src/xana-website/src/components/ui/Navbar/NavbarItem.jsx");
